<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
/**
 * Starter component
 */
export default {
  head() {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data() {
    return {
      title: "線上詢問列表",
      items: [
        {
          text: "客服管理",
          href: "/onlineinquiry",
        },
        {
          text: "線上詢問列表",
          active: true,
        }
      ],
      currentInquiryId: null,
      typeOptions: [
        { id: 0, name: " 全部" },
        { id: 1, name: "中天電視" },
        { id: 2, name: "中天新聞會員" },
        { id: 3, name: "我要爆料" },
        { id: 4, name: "我要投稿" },
        { id: 5, name: "商務合作" },
        { id: 6, name: "公關合作" },
        { id: 7, name: "快點購購物網" },
        { id: 8, name: "其他問題" }
      ],
      statusOptions: [
        { id: 0, name: " 未處理"},
        { id: 1, name: "處理中"},
        { id: 2, name: "已結案"}
      ],
      sendData: {
        comment: '',
        status: null
      },
      selectedStatus: null,
      inquiryData: {
        id: "",
        is_member: false,
        name: "",
        niname: "",
        job: "",
        content: "",
        county_id: "",
        cellphone: "",
        email: "",
        type: null,
        created_at: "",
        updated_at: "",
        files: [],
        status: null,
        process_logs: []
      },
      reOpenCase: false,
    };
  },
  components: {
    Layout,
    PageHeader,
    Multiselect
  },
  watch: {
    selectedStatus (_val) {
      this.sendData.status = _val?.id ?? null
    }
  },
  mounted() {
    this.currentInquiryId = this.$route.params.contactOrderHashID
    this.getData()
  },
  computed: {
    sortedProcessLogs() {
      return this.inquiryData.process_logs.reverse()
    },
    typeText() {
      return this.typeOptions.find(_type => _type.id === this.inquiryData.type)?.name || ''
    },
  },
  methods: {
    getData() {
      const vm = this
      this.$root.apis.getCustomerServiceDetail(
        this.currentInquiryId,
        function (_response){
          vm.inquiryData = _response.body.data
          if (_response.body.data.status === 2) {
            vm.reOpenCase = false
          }
        },
        function (_error) {
          console.error(_error.body.data)
          vm.$root.common.showErrorDialog(_error.body.data)
        }
      )
    },
    sendRequest() {
      const data = {
        comment: this.sendData.comment,
        status: this.sendData.status
      }
      const vm = this
      this.$root.apis.postCustomerServiceComment(
        this.currentInquiryId,
        data,
        function (_response){
          vm.$root.common.showSingleNotify('送出成功!')
          vm.getData()
        },
        function (_error) {
          console.error(_error.body.data)
          vm.$root.common.showErrorDialog(_error.body.data)
        }
      )
    }
  },
};
</script>

<template lang="pug">
Layout
  PageHeader(:title="title" :items="items")
  .row
    .col-lg-12.d-flex.justify-content-end
      b-button.width-md.mb-3(variant="secondary" @click="$router.go(-1)") 回上一層 
    .col-lg-12
      //- 基本資料
      b-card.mb-1(no-body)
        b-card-body
            span.type-badge {{ typeText }}
            div.mt-2.inquiry-info
              span {{ inquiryData.created_at }}
              span.ml-1(v-if="inquiryData.name") / {{ inquiryData.name }}
              span(v-if="inquiryData.niname") ({{ inquiryData.niname }})
              span.ml-1(v-if="inquiryData.county_id")  / {{ inquiryData.county_id }}
              span.ml-1(v-if="inquiryData.job")  / {{ inquiryData.job }}
              span.ml-1(v-if="inquiryData.cellphone")  / {{ inquiryData.cellphone }}
              span.ml-1(v-if="inquiryData.email")  / {{ inquiryData.email }}
            hr
            p.my-3 {{ inquiryData.content }}
            div.d-flex.align-items-center.mb-3
              .inquiry-files(
                v-for="(file, index) in inquiryData.files"
              )
                video.inquiry-file(
                  v-if="file.substring(file.length - 3) === 'mp4'"
                  :key="index"
                  controls
                )
                  source(:src="file" type="video/mp4")
                img.inquiry-file(
                  v-else
                  :key="index"
                  :src="file"
                  alt="image"
                )
            hr
            div.mb-2(v-for="log in sortedProcessLogs")
              span.text-italic {{ log.created_at }}
              span.ml-1 {{ log.editer?.name }}
              span.ml-1.text-italic(v-if="log.status === 0") （未處理）
              span.ml-1.text-italic(v-else-if="log.status === 1") （處理中）
              span.ml-1.text-italic(v-else-if="log.status === 2") （已處理）
              br
              span {{ log.content }}

            b-button.width-lg.my-3(
              variant="dark" 
              v-if="inquiryData.status === 2 && !reOpenCase" 
              @click="reOpenCase = true"
            ) 重新開啟案件

    .col-8.row.mt-3(v-if="inquiryData.status !== 2 || reOpenCase")
      .col-md-4
        .form-group
          multiselect(
            v-model="selectedStatus"
            :options="statusOptions", 
            placeholder="請選擇案件狀態",
            deselect-label="Can't remove this value"
            :multiple="false",
            :allow-empty="false",
            track-by="id",
            label="name"
            select-label=""
          )
      .col-md-12
        .form-group.d-flex.align-items-center
          b-form-textarea(
            id="textarea"
            v-model="sendData.comment"
            placeholder="請輸入備註內容"
            rows="3"
            max-rows="6"
          )
          b-button.width-lg.ml-2(
            variant="dark" 
            :disabled="sendData.comment === '' || sendData.status === null"
            @click="sendRequest"
          ) 送出
      
</template>
<style lang="sass" scoped>
.type-badge
  font-size: 1rem
  background: #C0E0F3
  color: #0089DB
  padding: 4px 28px
  border-radius: 20px

.inquiry-info
  color: #ACACAC
  font-size: 1rem
  font-weight: 400

.inquiry-files
  width: 200px
  height: 120px
  &:not(:last-child)
    margin-right: 1rem
.inquiry-file
  width: 100%
  height: 100%
  object-position: center
  object-fit: cover

.text-italic
  font-style: italic
</style>
